import React from 'react'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import {
  Container,
  Row,
  Col,
  FormGroup,
  Form,
  Input,
  Label,
  Button,
} from 'reactstrap'
import Layout from '../components/layout'

import Seo from '../components/shared/Seo'
import Icons from '../components/shared/Icons'

class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: 'Contact Us',
      email: '',
      form: {},
    }
  }

  encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }
  handleSubmit = e => {
    e.preventDefault()

    addToMailchimp(e.target[2].value, {
      COMPANY: e.target[1].value.toString(),
      PHONE: e.target[3].value.toString(),
      VEHICLES: e.target[4].value.toString(),
      INTERESTED: e.target[5].value.toString(),
      COMMENT: e.target[6].value.toString(),
    })
      .then(data => {
        document.getElementById('form').submit()
      })
      .catch(err => {
        document.getElementById('form').submit()
      })
  }

  componentDidMount() {
    if (
      this.props.location.state !== null &&
      this.props.location.state.selected !== null
    ) {
      this.setState({ selected: this.props.location.state.selected })
    }
    if (
      this.props.location.state !== null &&
      this.props.location.state.email !== null
    ) {
      this.setState({ email: this.props.location.state.email })
    }
  }
  render() {
    const { data, seo } = this.props.data
    return (
      <Layout>
        <Seo {...seo} />
        <Helmet>
          <script src="https://www.google.com/recaptcha/api.js" />
        </Helmet>
        <Container className="py-5">
          <Row>
            <Col md="4">
              <div
                className="p-5 bg-dark text-white mb-4"
                dangerouslySetInnerHTML={{
                  __html: data.contanctInfo.g.html,
                }}
              />
              <div>
                <iframe
                  title="uniqueTitle"
                  src={data.map.src}
                  width="100%"
                  height="450"
                  frameBorder="0"
                />
              </div>
            </Col>

            <Col md="8">
              <div className=" px-md-5 mt-md-0 mt-5">
                <Form
                  name="contact"
                  id="form"
                  method="POST"
                  onSubmit={this.handleSubmit}
                  data-netlify-honeypot="bot-field"
                  data-netlify="true"
                  data-netlify-recaptcha="true"
                >
                  <Input type="hidden" name="form-name" value="contact" />
                  <Row className="mb-3">
                    <Col md="6">
                      <FormGroup>
                        <Label
                          className="text-primary"
                          name="Company"
                          for="Company"
                        >
                          Company
                        </Label>
                        <Input
                          ref={this.company}
                          className="rounded-0"
                          type="text"
                          name="Company"
                          id="Company"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label className="text-primary" for="Email">
                          Email*
                        </Label>
                        <Input
                          name="Email"
                          className="rounded-0"
                          type="email"
                          onChange={e => {
                            this.setState({ email: e.target.value })
                          }}
                          required
                          value={this.state.email}
                          id="Email"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md="6">
                      <FormGroup>
                        <Label className="text-primary" for="Telephone">
                          Telephone*
                        </Label>
                        <Input
                          className="rounded-0"
                          type="text"
                          name="Telephone"
                          id="Telephone"
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label className="text-primary" for="vehicles">
                          No of Vehicles{' '}
                        </Label>
                        <Input
                          type="select"
                          name="No of Vehicles"
                          id="vehicles"
                          defaultselected="1"
                        >
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md="8">
                      <FormGroup>
                        <Label
                          className="text-primary"
                          name="Interested In"
                          for="interest"
                        >
                          Interested In:
                        </Label>
                        <Input
                          value={this.state.selected}
                          onChange={e => {
                            this.setState({ selected: e.target.value })
                          }}
                          type="select"
                          name="Interest in"
                          id="interest"
                        >
                          <option value="Demo for Proteo Enterprise">
                            {' '}
                            Demo for Proteo Enterprise
                          </option>
                          <option value="Demo For Proteo Lite">
                            {' '}
                            Demo For Proteo Lite
                          </option>
                          <option value="Demo For Proteo Mobile">
                            {' '}
                            Demo For Proteo Mobile
                          </option>
                          <option value="Get Proteo Trial Version">
                            {' '}
                            Get Proteo Trial Version
                          </option>
                          <option value="Request A Callback">
                            {' '}
                            Request A Callback
                          </option>
                          <option value="Quote for Proteo Enterprise">
                            {' '}
                            Quote for Proteo Enterprise
                          </option>
                          <option value="Quote for Proteo Lite">
                            {' '}
                            Quote for Proteo Lite
                          </option>
                          <option value="Quote for Proteo Plus">
                            {' '}
                            Quote for Proteo Plus
                          </option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md="12" className="mb-3">
                      <FormGroup>
                        <Label
                          className="text-primary"
                          name="Comment"
                          for="Comment"
                        >
                          Comment/Inquiry
                        </Label>
                        <Input
                          type="textarea"
                          name="Comment"
                          id="Comment"
                          style={{ minHeight: '300px' }}
                        />
                      </FormGroup>
                    </Col>
                    <Col
                      md="12"
                      className="d-flex flex-column align-items-center mb-3 text-center"
                    >
                      <FormGroup check>
                        <Label check className="mb-1">
                          <Input
                            type="checkbox"
                            name="Terms of Service"
                            required
                          />{' '}
                          By continuing you agree to our{' '}
                          <Link to="/privacy">Privacy Policy</Link> and{' '}
                          <Link to="/privacy">GDPR Policy</Link> *
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <div
                        className="g-recaptcha"
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          padding: '1em 0 2em 0',
                        }}
                        onChange={e => {}}
                        data-sitekey="6LfMXXsUAAAAAE-8CBpXFRoINV0wraJyxMHw9lnL"
                      />
                      <Button className="d-block mx-auto primary-btn-gradient py-2 px-5">
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
        <Icons />
      </Layout>
    )
  }
}

export default Contact

export const query = graphql`
  {
    seo: contentfulContactPage {
      title: pageTitle
      description
      keywords
    }
    data: contentfulContactPage {
      contanctInfo {
        g: childMarkdownRemark {
          html
        }
      }
      map: googleMapsSource {
        src: googleMapsSource
      }
    }
  }
`
